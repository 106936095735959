import * as React from "react";
import { PageProps } from "gatsby";
import Layout from "../components/layout";
import HeroSection from "../components/herosection";
import AboutSection from "../components/aboutsection";
import MintSection from "../components/mintsection";
import UniqueSection from "../components/uniquesection";
import RoadmapSection from "../components/roadmapsection";
import FAQSection from "../components/faqsection";

const IndexPage: React.VFC<PageProps> = () => {
  return (
    <Layout title={`Home`}>
      <HeroSection />
      <AboutSection />
      <MintSection />
      <UniqueSection />
      <RoadmapSection />
      <FAQSection />
    </Layout>
  );
};

export default IndexPage;
