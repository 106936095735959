import * as React from "react";
import { useWallet } from "@solana/wallet-adapter-react";
import { Toaster } from "react-hot-toast";
import Container from "../components/container";
import MintCard from "../components/mintcard";
import useCandyMachine from "../hooks/useCandyMachine";

import SINGLE from "../assets/gif/V1.1.gif";
import TRIPLE from "../assets/gif/V2.gif";
import FLOCK from "../assets/gif/V3.gif";
import { MintCountdown } from "./mintCountdown";

const MintSection: React.VFC = () => {
  const [isMintLive, setIsMintLive] = React.useState(false);
  const { isMinting, isSoldOut, startMint, startMintMultiple, nftsData } =
    useCandyMachine();
  const { connected } = useWallet();

  return (
    <Container id="mint" className="min-h-screen">
      <div className="h-36" />
      <Toaster />
      <div className="grid lg:grid-cols-4 gap-20 place-items-center">
        <div className="place-self-start">
          <h1 className="w-full header-text text-white text-center lg:text-right">
            Mint Your <br />
            Feather
          </h1>
          {connected && (
            <>
              <MintCountdown onMintLive={() => setIsMintLive(true)} />
              <p className="text-white text-lg text-right">
                <span className="font-bold">Available/Minted/Total:</span>{" "}
                {nftsData.itemsRemaining}/{nftsData.itemsRedeemed}/
                {nftsData.itemsAvailable}
              </p>
            </>
          )}
        </div>
        <MintCard
          onMintClick={startMint}
          disableMint={!isMintLive || isSoldOut || !connected}
          isMinting={isMinting}
          img={SINGLE}
          name="SINGLE"
          count={1}
          price={0.4}
          body="A single feather to collect."
        />
        <MintCard
          onMintClick={() => startMintMultiple(3)}
          disableMint={!isMintLive || isSoldOut || !connected}
          isMinting={isMinting}
          img={TRIPLE}
          name="TRIPLE"
          count={3}
          price={0.4}
          body="A collection of 3 feathers."
        />
        <MintCard
          onMintClick={() => startMintMultiple(10)}
          disableMint={!isMintLive || isSoldOut || !connected}
          isMinting={isMinting}
          img={FLOCK}
          name="FLOCK"
          count={10}
          price={0.4}
          body="A flock of 10 feathers for your collection."
        />
      </div>
    </Container>
  );
};

export default MintSection;
