import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import Container from "../components/container";
import Markdown from "./markdown";

const FAQSection: React.VFC = () => {
  const [activeList, setActiveList] = React.useState<string[]>([]);
  const { allMarkdownRemark } = useStaticQuery(query);
  const items = allMarkdownRemark.edges;

  const toggle = (id: string) => {
    if (activeList.includes(id)) {
      setActiveList(activeList.filter((item) => item !== id));
    } else {
      setActiveList([...activeList, id]);
    }
  };

  return (
    <Container id="faq" className="min-h-screen flex flex-col mb-24">
      <div className="h-36" />
      <div className="w-full grid grid-cols-1 sm:grid-cols-4">
        <div className="flex flex-col items-center mb-8 sm:mb-0">
          <h1 className="header-text text-white text-center">FAQ</h1>
          <div className="w-px h-full" style={{ backgroundColor: "#989898" }} />
        </div>
        <AnimatePresence>
          <div className="col-span-3 space-y-4">
            {items.map(({ node }) => (
              <div key={node.id}>
                <div className="cursor-pointer" onClick={() => toggle(node.id)}>
                  <h2 className="subheader-text-2 text-white">
                    {node.frontmatter.question}
                  </h2>
                </div>
                {activeList.includes(node.id) && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                  >
                    <Markdown className="ml-4 mt-2" html={node.html} />
                  </motion.div>
                )}
              </div>
            ))}
          </div>
        </AnimatePresence>
      </div>
    </Container>
  );
};
const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "faq" } } }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            question
          }
        }
      }
    }
  }
`;

export default FAQSection;
