import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { btnMotionConfig } from "../utils/animation";
import Container from "../components/container";
import Markdown from "./markdown";
import { shuffle, slice } from "lodash";

import DiscordIcon from "../assets/svg/discord.inline.svg";
import TwitterIcon from "../assets/svg/twitter.inline.svg";

import LeftArrowIcon from "../assets/svg/hash-arrow-left.inline.svg";
import RightArrowIcon from "../assets/svg/hash-arrow-right.inline.svg";
import DotIcon from "../assets/svg/dot.inline.svg";
import ActiveDotIcon from "../assets/svg/active-dot.inline.svg";

import SwiperCore, { Autoplay, EffectFade } from "swiper";
SwiperCore.use([Autoplay, EffectFade]);

import "swiper/css";
import "swiper/css/effect-fade";

const HeroSection: React.VFC = () => {
  const {
    heroSection,
    site: { siteMetadata },
    previewImages,
  } = useStaticQuery(query);

  const slides = React.useMemo(
    () => slice(shuffle(previewImages.edges), 0, 5),
    [previewImages.edges]
  );

  const SwiperRef = React.useRef<SwiperCore>();
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);

  const handleSlideChange = (swiper: SwiperCore) => {
    setActiveSlideIndex(swiper.activeIndex);
  };

  return (
    <Container className="min-h-screen">
      <div className="h-36" />
      <div className="h-full grid grid-cols-4">
        <div className="col-span-2 col-start-2 place-self-center lg:col-span-1 lg:col-start-1 lg:place-self-auto">
          <Swiper
            effect="fade"
            slidesPerView={1}
            spaceBetween={30}
            autoplay={{ delay: 5000, disableOnInteraction: true }}
            onActiveIndexChange={handleSlideChange}
            onSwiper={(swiper) => (SwiperRef.current = swiper)}
          >
            {slides.map(({ node: { id, name, childImageSharp } }) => (
              <SwiperSlide key={id}>
                <div className="max-h-screen w-full">
                  <GatsbyImage
                    alt={`FeatherNFT Preview image (${name})`}
                    image={childImageSharp.gatsbyImageData}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="flex items-center max-w-xs px-6">
            <LeftArrowIcon
              className="hidden sm:block w-6 h-6 cursor-pointer"
              onClick={() => SwiperRef.current?.slidePrev()}
            />
            <div className="relative flex-1 flex flex-col justify-center">
              <span
                className="hidden sm:block absolute inset-x-0 h-px"
                style={{ backgroundColor: "#D3D3D3" }}
              />
              <div className="relative flex items-center justify-around px-4">
                {slides.map((_, index) => (
                  <div>
                    {index === activeSlideIndex ? (
                      <ActiveDotIcon className="w-4 h-4" />
                    ) : (
                      <DotIcon
                        className="w-4 h-4 cursor-pointer"
                        onClick={() => SwiperRef.current?.slideTo(index)}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
            <RightArrowIcon
              className="hidden sm:block w-6 h-6 cursor-pointer"
              onClick={() => SwiperRef.current?.slideNext()}
            />
          </div>
        </div>
        <div className="col-span-4 lg:col-span-3 flex items-center justify-center">
          <div className="max-w-3xl space-y-5">
            <Markdown html={heroSection.html} />
            <div className="flex flex-col sm:flex-row items-center space-y-5 space-x-0 sm:space-y-0 sm:space-x-5 pt-2">
              <motion.a
                href={siteMetadata.discordUrl}
                target="_blank"
                {...btnMotionConfig}
                className="w-full relative subtitle-text text-white flex sm:inline-flex sm:w-auto items-center justify-center py-4 px-6"
              >
                <DiscordIcon className="h-5 mr-4" /> JOIN OUR DISCORD
                <span className="absolute inset-0 border-2 border-white rounded-lg filter drop-shadow-button-white" />
              </motion.a>
              <motion.a
                href={siteMetadata.twitterUrl}
                target="_blank"
                {...btnMotionConfig}
                className="w-full relative subtitle-text text-white flex sm:inline-flex sm:w-auto items-center justify-center py-4 px-6"
              >
                <TwitterIcon className="h-5 mr-4" /> FLY WITH US
                <span className="absolute inset-0 border-2 border-app-highlight-3 rounded-lg filter drop-shadow-button-blue" />
              </motion.a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const query = graphql`
  {
    site {
      siteMetadata {
        twitterUrl
        discordUrl
      }
    }

    heroSection: markdownRemark(frontmatter: { id: { eq: "hero-section" } }) {
      html
    }

    previewImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "preview" }
      }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              aspectRatio: 0.5625
              height: 1000
            )
          }
        }
      }
    }
  }
`;

export default HeroSection;
