import { useContext } from "react";
import BalanceContext from "./balanceContext";

function useWalletBalance() {
  if (typeof window === "undefined") return [0, () => {}];
  const [balance, setBalance]: any = useContext(BalanceContext);
  return [balance, setBalance];
}

export default useWalletBalance;
