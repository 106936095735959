import * as React from "react";
import cx from "classnames";

interface MarkdownProps extends React.ComponentPropsWithoutRef<"div"> {
  html: string;
}

const Markdown: React.VFC<MarkdownProps> = ({ html, className, ...rest }) => {
  return (
    <div
      className={cx(className, "markdown")}
      dangerouslySetInnerHTML={{ __html: html }}
      {...rest}
    />
  );
};

export default Markdown;
