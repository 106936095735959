import * as React from "react";
import Container from "../components/container";

import UncheckedRadio from "../assets/svg/unchecked-radio.inline.svg";
import CheckedRadio from "../assets/svg/checked-radio.inline.svg";
import RightArrow from "../assets/svg/right-arrow-white.inline.svg";
import { graphql, useStaticQuery } from "gatsby";
import Markdown from "./markdown";

const RoadmapSection: React.VFC = () => {
  const { allMarkdownRemark } = useStaticQuery(query);

  const data = allMarkdownRemark.edges;

  return (
    <Container id="roadmap" className="min-h-screen flex flex-col">
      <div className="h-36" />
      <div>
        <h1 className="header-text text-white text-center">Project Roadmap</h1>
        <p className="body-text text-white text-center py-5">
          A Fine Art project <b className="font-bold">deflationary</b> and
          finite by Nature.
        </p>
        <div className="text-white text-center flex flex-col items-center space-y-2 py-5">
          <span className="subtitle-text text-app-highlight-3">
            ‘Finite’ [ /fʌɪnʌɪt/ ]
          </span>
          <p>limited in size or extent.</p>
          <br />
          <span className="subtitle-text text-app-highlight-3">
            ‘Deflationary’ [ /diːˈfleɪʃənri/ ]
          </span>
          <p className="max-w-2xl">
            a contraction in the supply of available assets or ‘art pieces’ that
            results in a relative increase in unit value due to increased
            scarcity.
          </p>
        </div>
      </div>
      <div className="flex lg:flex-col mt-20 sm:px-20 lg:px-0">
        <div className="relative ml-2.5">
          <div className="absolute inset-y-0 w-px lg:inset-x-0 lg:h-px lg:w-auto bg-gray-500" />
          <RightArrow className="absolute bottom-0 -left-2.5 transform rotate-90 lg:transform-none lg:left-auto lg:bottom-auto lg:right-0 lg:-top-2.5 w-5" />
        </div>
        <div className="col-span-3 relative grid grid-cols-1 lg:grid-cols-4 gap-10 py-20 lg:py-0">
          {data.map(({ node: { html, id } }) => (
            <div className="flex items-start lg:flex-col lg:items-center">
              <div className="flex lg:flex-col items-center -ml-5 lg:ml-0 lg:-mt-5 mr-5 lg:mr-0 lg:mb-5">
                <UncheckedRadio className="w-10 h-10" />
                <div className="w-4 sm:w-20 h-px lg:h-20 lg:w-px bg-gray-500" />
                <div className="w-5 h-5 rounded-full border border-gray-500" />
              </div>
              <Markdown key={id} html={html} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};
const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "roadmap" } } }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          html
        }
      }
    }
  }
`;

export default RoadmapSection;
