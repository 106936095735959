import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { btnMotionConfig } from "../utils/animation";
import cx from "classnames";
import { motion } from "framer-motion";
import { useWallet } from "@solana/wallet-adapter-react";
import useCandyMachine from "../hooks/useCandyMachine";

interface MintCardProps {
  img: any;
  name: string;
  count: number;
  price: number;
  onMintClick(): void;
  disableMint: boolean;
  isMinting: boolean;
  body: string;
}

const MintCard: React.VFC<MintCardProps> = ({
  onMintClick,
  disableMint,
  img,
  name,
  count,
  price,
  isMinting,
  body,
}) => {
  const { connected } = useWallet();
  const { isSoldOut } = useCandyMachine();

  const handleMint = () => {
    if (!disableMint && connected) {
      onMintClick();
    }
  };

  console.log({ disableMint });

  return (
    <div className="max-w-xs lg:max-w-full">
      <div className="relative">
        <img
          src={img}
          alt="FeatherNFT"
          className="filter drop-shadow-image-light"
        />
        <div className="absolute inset-0">
          <StaticImage src="../assets/images/mint-cover.png" alt="mint cover" />
        </div>
      </div>
      <div className="mt-10">
        <h3 className="subheader-text-2 text-white">
          MINT {name} (X{count})
        </h3>
        <p className="body-text text-white mt-3">{body}</p>
        <div className="mt-4">
          {!connected && (
            <p className="text-white text-app-highlight-1 py-2 font-bold">
              connect wallet to mint!
            </p>
          )}
          {isSoldOut && (
            <p className="text-white text-app-highlight-1 py-2 font-bold">
              SOLD OUT
            </p>
          )}
          <div
            className="flex items-center justify-between border-t py-4 "
            style={{ borderColor: "#535353" }}
          >
            <span className="subheader-text-2 text-white">
              {(price * count).toFixed(2)} SOL
            </span>

            <motion.button
              {...(!(disableMint || isMinting) ? btnMotionConfig : {})}
              className={cx(
                "relative body-text text-white flex items-center py-2 px-6",
                { "cursor-not-allowed opacity-60": disableMint || isMinting }
              )}
              onClick={handleMint}
            >
              {isMinting ? "MINTING" : "MINT"}
              <span className="absolute inset-0 border-2 border-app-highlight-3 rounded-lg filter drop-shadow-button-blue" />
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintCard;
