import * as React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import Markdown from "../markdown";

interface AboutCardProps {
  img: IGatsbyImageData;
  alt: string;
  html: string;
}

const AboutCard: React.VFC<AboutCardProps> = ({ img, alt, html }) => {
  return (
    <div>
      <GatsbyImage
        alt={alt}
        image={img}
        className="filter drop-shadow-image-light"
      />
      <Markdown className="mt-10" html={html} />
    </div>
  );
};

export default AboutCard;
