import * as React from "react";
import Container from "../../components/container";
import { graphql, useStaticQuery } from "gatsby";
import AboutCard from "./aboutcard";

const AboutSection: React.VFC = () => {
  const { allMarkdownRemark } = useStaticQuery(query);

  return (
    <Container id="about" className="min-h-screen">
      <div className="h-36" />
      <h1 className="header-text text-white text-center my-14">The Feathers</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
        {allMarkdownRemark.edges.map(({ node: { id, html, img } }) => (
          <AboutCard
            key={id}
            img={img.data.childImageSharp.gatsbyImageData}
            alt={img.alt}
            html={html}
          />
        ))}
      </div>
    </Container>
  );
};
const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "about" } } }
      sort: { fields: [frontmatter___order] }
    ) {
      edges {
        node {
          id
          html
          img: frontmatter {
            alt: featuredImage_alt
            data: featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutSection;
