import * as React from "react";
import useCandyMachine from "../hooks/useCandyMachine";
import Countdown from "react-countdown";

interface MintCountdownProps {
  onMintLive(): void;
}

export const MintCountdown: React.VFC<MintCountdownProps> = ({
  onMintLive,
}) => {
  const { mintStartDate } = useCandyMachine();
  const [tic, setTic] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTic(tic + 1);
      if (mintStartDate.getTime() > new Date().getTime()) {
        clearInterval(interval);
      }
    }, 100);
  }, []);

  return (
    <div className="w-full flex flex-col items-end py-12">
      <Countdown
        key={tic}
        className="text-white subheader-text"
        date={new Date("29 Oct 2021 18:00:00 UTC")}
        onMount={({ completed }) => completed && onMintLive()}
        onComplete={({ completed }) => completed && onMintLive()}
      />
      <p className="text-white block uppercase text-xs mt-2">
        Left to open public mint
      </p>
    </div>
  );
};
