import * as React from "react";
import Container from "../components/container";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import Markdown from "./markdown";

const UniqueSection: React.VFC = () => {
  const { data } = useStaticQuery(query);

  const { featuredImage, featuredImage_alt: alt } = data.frontmatter;
  const image = featuredImage.childImageSharp.gatsbyImageData;

  return (
    <Container className="min-h-screen flex flex-col">
      <div className="h-36" />
      <div className="grid lg:grid-cols-4 gap-16 flex-grow place-items-center">
        <div className="lg:col-span-3">
          <GatsbyImage
            alt={alt}
            image={image}
            className="filter drop-shadow-image-light"
          />
        </div>
        <Markdown
          className="space-y-5 row-start-1 lg:row-start-auto"
          html={data.html}
        />
      </div>
    </Container>
  );
};
const query = graphql`
  {
    data: markdownRemark(frontmatter: { category: { eq: "unique-section" } }) {
      html
      frontmatter {
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        featuredImage_alt
      }
    }
  }
`;

export default UniqueSection;
